import NoSSR from 'Common/hoc/NoSSR';
import AnonymousUserNavigation from '../AnonymousUserNavigation';
import CustomerLocationSelect from '../CustomerLocationSelect';
import CustomerNavigation from '../server/CustomerNavigation';
import { customerNavItems } from '../server/NavigationItems/items';

type location = {
  id: string;
  streetAddress: string;
  placeId: string;
}

type NavigationWithLocationSelectProps = {
  onSelect?: (id: string) => void;
  onUpdate?: () => void;
  value?: string | null;
  locations: location[];
  isTemporaryUser: boolean;
  children?: React.ReactNode;
}

const NavigationWithLocationSelect: React.FC<
  NavigationWithLocationSelectProps
> = ({
  onSelect,
  onUpdate,
  value,
  locations,
  isTemporaryUser,
  children,
}) => {

  const sharedLocationSelect = locations ? (
    <NoSSR>
      <CustomerLocationSelect
        value={value}
        locations={locations}
        isTemporaryUser={isTemporaryUser}
        onSelect={onSelect}
        onUpdate={onUpdate}
        submitButtonCopy={isTemporaryUser ? 'Browse Services In My Area' : undefined}
      />
    </NoSSR>
  ) : null;

  const navigation = (
    <div>
      {isTemporaryUser ? (
        <AnonymousUserNavigation>
          {sharedLocationSelect}
        </AnonymousUserNavigation>
      ) : (
        <CustomerNavigation items={customerNavItems}>
          {sharedLocationSelect}
        </CustomerNavigation>
      )}
      {children}
    </div>
  );

  return navigation;
};

export default NavigationWithLocationSelect;

