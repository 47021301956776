import { Modal } from 'antd';
import { getCookie, AppCookie, setCookie } from 'Common/functions/Cookie';
import {
  LocationType,
  useV2Gateway_GetLocationLazyQuery,
  V2Gateway_QueryLocationsQueryHookResult,
} from 'Generated/graphql';
import LocationForm from '../LocationForm';
import { LocationModFieldKey } from '../LocationForm/functions';

type locations = NonNullable<
  V2Gateway_QueryLocationsQueryHookResult['data']
>['queryLocations']['locations']
type location = Omit<locations[number], 'serviceAreas'>

type PromptCreateHomeLocation = {
  onFlowComplete?: (location: location) => void;
  preventClose?: boolean;
  isOpen: boolean;
  onClose: () => void;
  onBeforeSubmit?: (next: () => void) => void;
  isSubmitDisabled?: boolean;
}

const isSSR = typeof window === 'undefined';

const PromptCreateHomeLocation: React.FC<
  PromptCreateHomeLocation
> = ({
  onFlowComplete,
  preventClose = false,
  isOpen,
  onClose,
  onBeforeSubmit,
  isSubmitDisabled,
}) => {
  const timesClosedStr = isSSR ?
    null :
    getCookie(AppCookie.ClosedJoinSharedLocationPrompt);
  const timesClosed = timesClosedStr ? parseInt(timesClosedStr) : 0;
  const [getLocation] = useV2Gateway_GetLocationLazyQuery();

  const handleLocationCreated = (locationUid: string) => {
    getLocation({
      variables: {
        locationUid,
      }
    }).then(async ({ data }) => {
      if (data) {
        const loc = data.getLocation;
        if (onFlowComplete) {
          onFlowComplete(loc);
        }
      }
    });
  };

  return (
    <Modal
      open={isOpen}
      title=""
      footer={<></>}
      closable={!preventClose}
      onCancel={() => {
        if (preventClose) {
          return;
        }

        const tc = timesClosed ? timesClosed + 1 : 1;
        setCookie(
          AppCookie.ClosedJoinSharedLocationPrompt,
          tc.toString(),
        );

        onClose();
      }}
    >
      <div className="flex">
        <div className="flex justify-center w-full">
          <div className="flex flex-col w-full">
            <span className='title'>
              Where would you like this service?
            </span>
            <LocationForm
              hideFields={[
                LocationModFieldKey.Type,
                LocationModFieldKey.Name,
                LocationModFieldKey.PhoneNumber,
              ]}
              overrideFields={{
                name: 'Temporary Location',
                type: LocationType.Individual,
                phoneNumber: '0000000000',
              }}
              onCreateLocation={location => {
                handleLocationCreated(location.uid);
              }}
              submitButtonCopy='View Services In My Area'
              onBeforeSubmit={onBeforeSubmit}
              isDisabled={isSubmitDisabled}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PromptCreateHomeLocation;

