'use client';
import { AccountType } from 'Common/functions/AccountType';
import { useRouter } from 'next/navigation';
import { AppRoutePath } from 'Pages/routes';
import { useEffect, useMemo, useState } from 'react';
import { AppCookie, getCookie, setCookie } from 'Common/functions/Cookie';
import { useGetLocationsForNavQuery, useGetUserQuery } from 'Generated/graphql';
import 'Clients/FireBaseClient';
import BrowsePageContent from '../BrowsePageContent';
import NavigationWithLocationSelect from '../NavigationWithLocationSelect';
import { sortLocationsByStreetAddress } from 'Common/functions/Location';
import { getInitialSelectedLocationId } from 'Common/functions/LocationCookie';

const BrowseServiceTypes = () => {
  const router = useRouter();

  const locationsQuery = useGetLocationsForNavQuery();
  const locs = locationsQuery.data?.getLocations;
  const locationSubs = locationsQuery.data?.getLocationSubscriptions;
  const combinedLocations = (locs || []).concat(locationSubs?.map(s => s.location) || []);
  const locations = sortLocationsByStreetAddress(combinedLocations);

  const getUserQuery = useGetUserQuery();
  const user = getUserQuery.data?.getUser;
  const accountType = user?.accountType;
  const ownerUid = user?.uid;
  const isTemporaryUser = user === undefined || user.isTemporary;

  const defaultSelectedLocationId =
    getCookie(AppCookie.CustomerSelectedLocationId);
  const initialSelectedLocationId =
    getInitialSelectedLocationId(locations, defaultSelectedLocationId);

  const [selectedLocationId, setSelectedLocationId] =
    useState<string | null>(initialSelectedLocationId);

  const selectedLocation = useMemo(
    () => locations && locations.length > 0 ? locations.find(l => l.id === selectedLocationId) || locations[0] : undefined,
    [locations, selectedLocationId],
  );

  useEffect(() => {
    if (accountType === AccountType.LocationOwner) {
      router.push(AppRoutePath.BUSINESS_BROWSE_SERVICE_TYPES);
    } else if (accountType === AccountType.ServiceProvider) {
      router.push(AppRoutePath.DASHBOARD);
    }
  }, [accountType]);

  useEffect(() => {
    const defaultSelectedLocationId =
      getCookie(AppCookie.CustomerSelectedLocationId);
    const initialSelectedLocationId =
      getInitialSelectedLocationId(locations, defaultSelectedLocationId);

    if (initialSelectedLocationId !== selectedLocationId) {
      setSelectedLocationId(initialSelectedLocationId);
    }
  }, [locations.length]);

  const navigation = (
    <NavigationWithLocationSelect
      value={selectedLocationId}
      locations={locations}
      isTemporaryUser={isTemporaryUser}
      onSelect={id => {
        if (ownerUid) {
          setSelectedLocationId(id);
          setCookie(AppCookie.CustomerSelectedLocationId, id, 14);
        }
      }}
      onUpdate={() => {
        locationsQuery.refetch();
      }}
    />
  );

  return (
    <BrowsePageContent
      user={user || null}
      routerPush={router.push}
      refetchLocations={locationsQuery.refetch}
      selectedLocation={selectedLocation || null}
      navigation={navigation}
    />
  );
};

export default BrowseServiceTypes;

