import { ThemeUIStyleObject } from 'theme-ui';

const styles: Record<string, ThemeUIStyleObject> = {
  accountInfoInnerContainer: {
    textAlign: 'left',
    borderRadius: '8px',
    minWidth: '245px',
    position: 'relative',
    '> :first-of-type': {
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
    },
    '> :last-of-type': {
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px',
    },
  },
  accountItems: {
    justifyContent: 'start',
    alignItems: 'center',
    width: ['auto', 'auto', '100%'],
    fontSize: '1.125rem',
    color: 'primary',
    p: '0.7rem',
  },
};

export default styles;

