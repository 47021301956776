import { ServiceType } from '@bookpeazy/common-js';

export const enabledCustomerServiceTypes = [
  ServiceType.DRY_CLEAN,
  ServiceType.OIL_CHANGE,
  ServiceType.MASSAGE,
  ServiceType.ESTHETICIAN,
  ServiceType.HAIRCUT,
  ServiceType.PHYSIOTHERAPY,
  //ServiceType.DIETITIAN,
  ServiceType.FITNESS_CLASS,
  //ServiceType.PET_SERVICES,
  ServiceType.PET_GROOMING,
  ServiceType.CAR_WASH,
  ServiceType.DENTAL,
];

export const enabledBusinessServiceTypes = [
  ServiceType.DRY_CLEAN,
  ServiceType.OIL_CHANGE,
  ServiceType.MASSAGE,
  ServiceType.ESTHETICIAN,
  ServiceType.HAIRCUT,
  ServiceType.CAR_WASH,
  ServiceType.DIETITIAN,
  //ServiceType.WELLNESS,
  //ServiceType.ERGONOMICS,
  ServiceType.PHYSIOTHERAPY,
  ServiceType.FITNESS_YOGA,
  ServiceType.CHEF_SERVICES,
  ServiceType.WINE_TASTING,
  //ServiceType.PET_SERVICES,
  //ServiceType.PERSONAL_SHOPPING,
  //ServiceType.REFLEXOLOGY,
  //ServiceType.REIKI,
  ServiceType.WORKSHOP,
  ServiceType.DIETITIAN,
  ServiceType.CAR_WASH,
  ServiceType.FLU_CLINIC,
  ServiceType.DENTAL,
  ServiceType.PET_GROOMING,
];
