import { ThemeConfig } from 'antd';

export const AntdConfigProviderTheme: ThemeConfig = {
  token: {
    fontFamily: '"Proxima Nova", sans-serif',
    fontWeightStrong: 400,
    colorPrimary: '#f47451',
    borderRadius: 999,
    borderRadiusSM: 4,
  },
  components: {
    Modal: {
      titleFontSize: 22,
      titleLineHeight: 1.2,
    }
  }
};


