import dynamic from 'next/dynamic';
import React from 'react';

type NoSSRProps = {
  children?: React.ReactNode;
}

const NoSsr: React.FC<NoSSRProps> = ({ children }) => (
  <>{children}</>
);

export default dynamic(() => Promise.resolve(NoSsr), {
  ssr: false
});
