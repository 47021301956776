import React, { useMemo, useState } from 'react';
import {
  LocationType,
  V2Gateway_GetLocationsByUidQueryHookResult,
  useCreateLocationMutation,
} from 'Generated/graphql';
import { Box } from 'theme-ui';
import styles from './style';
import ChevronDownLarge from 'Images/svg/chevron-down-large.turbopack-temp-svg';
import LocationForm from '../LocationForm';
import { LocationModFieldKey } from '../LocationForm/functions';
import { dedupeLocations } from 'Common/functions/Location';
import { Modal } from 'antd';
import CreateLocationForm from '../v2/CreateLocationForm';

export type SubscribedLocationSelectLocations = NonNullable<
  V2Gateway_GetLocationsByUidQueryHookResult['data']
>['getLocationsByUid']

type location = {
  id: string;
  streetAddress: string;
  placeId: string;
}

export type SubscribedLocationSelectProps = {
  value: string | null | undefined;
  onSelect?: (id: string) => void;
  onUpdate?: () => void;
  submitButtonCopy?: string;
  locations: location[];
  isTemporaryUser: boolean;
}

const floatingContainerClasses = 'bg-paleOrange absolute rounded top-[100%] mt-8 ';

const CustomerLocationSelect: React.FC<
  SubscribedLocationSelectProps
> = React.memo(({
  value,
  onSelect,
  onUpdate,
  submitButtonCopy,
  locations: allLocations,
  isTemporaryUser,
}) => {
  const locations = dedupeLocations(allLocations);
  const [isOpen, setIsOpen] = useState(false);
  const [newLocationModalOpen, setNewLocationModalOpen] = useState(false);
  const onMouseLeave = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const childNode = e.relatedTarget as Node;
    const childNodeIsNode = typeof childNode.childNodes !== 'undefined';
    const isChildOfThis = (childNodeIsNode &&
      e.currentTarget.contains(childNode));
    if (childNode && isChildOfThis || e.relatedTarget === window) {
      // Ignore: Mouse is still within the parent, over a child element
      return;
    }

    setIsOpen(false);
  };
  const [createLocation, createLocationQuery] = useCreateLocationMutation();

  const MinimalLocationListM = useMemo(() => {
    const currentLocation = locations.find(l => l.id === value);

    const locationsListContent = (
      <div
        className={floatingContainerClasses}
        onMouseLeave={onMouseLeave}
      >
        <Box sx={styles.accountInfoInnerContainer}>
          {locations.map(
            l => (
              <div
                className="flex flex-col justify-start items-center sm:w-auto md:w-full p-3 hover:font-bold "
                onClick={e => {
                  e.stopPropagation();
                  setIsOpen(false);
                  onSelect && onSelect(l.id);
                }}
                key={l.id}
              >
                {l.streetAddress}
              </div>
            )
          )}
        </Box>
        <span
          className="p-4 text-center hover:font-bold "
          onClick={() => {
            setNewLocationModalOpen(true);
          }}
        >
          Add Location
        </span>
      </div>
    );

    const defaultAddress = currentLocation?.streetAddress ||
      (locations.length > 0 ? locations[0].streetAddress : undefined);

    const editLocationContent = (
      <div
        data-testid="subscribedLocationSelect_locationFormWrap"
        className={floatingContainerClasses + 'min-w-[190px]'}
        onMouseLeave={onMouseLeave}
      >
        <div className="p-4">
          <LocationForm
            hideFields={[
              LocationModFieldKey.Type,
              LocationModFieldKey.Name,
              LocationModFieldKey.PhoneNumber,
            ]}
            defaultAddress={defaultAddress}
            overrideFields={{
              type: LocationType.Individual,
              placeId: currentLocation?.placeId,
              name: '',
            }}
            onUpdate={() => {
              onUpdate && onUpdate();
            }}
            onCreateLocation={location => {
              onUpdate && onUpdate();
              onSelect && onSelect(location.id);
            }}
            submitButtonCopy={submitButtonCopy}
          />
        </div>
      </div>
    );

    const isShowingLocationForm = locations.length === 0 || isTemporaryUser;

    const content = isShowingLocationForm ?
      editLocationContent :
      locationsListContent;

    return (
      <div
        className="flex relative max-w-full justify-center items-center"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <div className="flex flex-col items-start gap-1 cursor-pointer relative">
          <span className="font-bold text-white sm:text-xs md:text-lg">Location</span>
          <div className="flex items-center">
            <span
              className="section-heading text-white font-normal sm:text-xs md:text-lg"
              data-cy="subscribedLocationSelect_selectedAddress"
            >
              {currentLocation ?
                currentLocation.streetAddress :
                'Enter Your Address'}&nbsp;
            </span>
            <div
              className="sm:max-w-2 md:max-w-4"
              data-testid="subscribedLocationSelect_chevronDown"
            >
              <ChevronDownLarge className="overflow-visible stroke-white stroke-[4]" />
            </div>
          </div>
          {isOpen && content}
        </div>
      </div>
    );
  }, [
    value,
    isOpen,
    locations,
  ]);

  if (locations.length === 0) {
    return <></>;
  }

  return (
    <>
      {MinimalLocationListM}
      <Modal
        title="Create Location"
        open={newLocationModalOpen}
        footer={null}
        onCancel={() => setNewLocationModalOpen(false)}
        onClose={() => setNewLocationModalOpen(false)}
      >
        <CreateLocationForm
          hideName
          loading={createLocationQuery.loading}
          onLocationCreated={id => {
            setNewLocationModalOpen(false);
            onUpdate && onUpdate();
            if (id) {
              onSelect && onSelect(id);
            }
          }}
          onCancel={() => setNewLocationModalOpen(false)}
          createLocation={data => createLocation({
            variables: {
              data,
            }
          }).then(d => d.data?.createLocation.location.id)}
        />
      </Modal>
    </>
  );
});

export default CustomerLocationSelect;

