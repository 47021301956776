import React, { useEffect, useState } from 'react';
import {
  CreateLocationInput,
  LocationType,
  UpdateLocationInput,
  useCreateLocationMutation,
  useV2Gateway_UpdateLocationsMutation,
  V2Gateway_GetLocationsQueryHookResult as GetLocationsQueryHookResult,
  CreateLocationMutationResult,
} from 'Generated/graphql';
import {
  getFormItems,
  getLocationFormFormikConfig,
  getLocationFormSubmitFn,
  LocationModFieldKey,
  getLocationFormCreateFn,
  getLocationFormUpdateFn,
  doSubmitLocationForm,
} from './functions';
import { Formik, useFormikContext } from 'formik';
import { FormChainLinkSubmitFuncReturn } from 'Common/context/FormChainContextProvider';
import CenteredSpinner from '../CenteredSpinner';

export type LocationFormValues = CreateLocationInput | UpdateLocationInput

export type CreateLocationMutationReturn = {
  internalUids: string[];
}

type existingLocation = Omit<NonNullable<
  GetLocationsQueryHookResult['data']
>['getLocations'][number], 'serviceAreas'>

type submitFn = (v: LocationFormValues) => Promise<
  FormChainLinkSubmitFuncReturn<CreateLocationMutationReturn | { location: createdLocation }>
>

type LocationFormProps = {
  defaultAddress?: string;
  existingLocation?: existingLocation;
  hideFields?: LocationModFieldKey[];
  submitFn: submitFn;
  overrideFields?: Partial<LocationFormValues>;
  hideTypeOptions?: LocationType[];
  isLoading: boolean;
  children?: React.ReactNode;
  isDisabled?: boolean;
  submitButtonCopy?: string;
  onBeforeSubmit?: (next: () => void) => void;
}

const LocationForm: React.FC<LocationFormProps> = ({
  hideFields: pHideFields = [],
  defaultAddress,
  existingLocation,
  submitFn,
  overrideFields,
  hideTypeOptions,
  isLoading,
  children,
  isDisabled,
  submitButtonCopy,
  onBeforeSubmit,
}) => {
  const {
    values,
    validateForm,
    setFieldValue,
    getFieldProps,
  } = useFormikContext<LocationFormValues>();
  const formValueType = getFieldProps('type').value;
  const hideFields = formValueType === LocationType.Partner ?
    [...pHideFields, LocationModFieldKey.ExtendedAddress] :
    pHideFields;
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (overrideFields) {
      Object.entries(overrideFields).forEach(([k, v]) => {
        setFieldValue(k, v);
      });
    }
  }, [overrideFields]);

  return (
    <div
      className="flex flex-col w-full gap-4"
    >
      {getFormItems(
        hideFields,
        existingLocation,
        hideTypeOptions,
        defaultAddress,
      )}
      <div>
        {children}
      </div>
      <div
        className="flex justify-center"
      >
        <button
          className={`btn-primary mb-4 flex justify-center ${isDisabled ? 'opacity-40 cursor-not-allowed' : ''}`}
          data-testid="locationFormSubmit"
          onClick={() => {
            if (isDisabled || isLoading || isSubmitting) {
              return;
            }

            setIsSubmitting(true);

            doSubmitLocationForm(
              validateForm,
              submitFn,
              values,
              onBeforeSubmit,
            ).then(() => {
              setIsSubmitting(false);
            });
          }}
          disabled={isLoading || isDisabled || isSubmitting}
        >
          {(isLoading || isSubmitting) ?
            <CenteredSpinner /> :
            submitButtonCopy || 'Save'}
        </button>
      </div>
    </div>
  );
};

type location = NonNullable<
  GetLocationsQueryHookResult['data']
>['getLocations'][number]

type createdLocation = NonNullable<
  CreateLocationMutationResult['data']
>['createLocation']['location']

type LocationFormWithFormikProps = {
  defaultAddress?: string;
  existingLocation?: Omit<location, 'serviceAreas'>;
  hideFields?: LocationModFieldKey[];
  onCreateLocation?: (location: createdLocation) => void;
  onUpdate?: () => void;
  overrideFields?: Partial<LocationFormValues>;
  hideTypeOptions?: LocationType[];
  children?: React.ReactNode;
  isDisabled?: boolean;
  submitButtonCopy?: string;
  onBeforeSubmit?: (next: () => void) => void;
}

const LocationFormWithFormik: React.FC<LocationFormWithFormikProps> = ({
  defaultAddress,
  existingLocation,
  hideFields = [],
  onCreateLocation,
  onUpdate,
  overrideFields,
  hideTypeOptions,
  children,
  isDisabled,
  submitButtonCopy,
  onBeforeSubmit,
}) => {
  const [
    createLocation,
    createLocationQuery,
  ] = useCreateLocationMutation();
  const [
    updateLocations,
    updateLocationsQuery,
  ] = useV2Gateway_UpdateLocationsMutation();

  const submit = getLocationFormSubmitFn(
    getLocationFormCreateFn(createLocation),
    getLocationFormUpdateFn(updateLocations),
  );

  useEffect(() => {
    const { data } = createLocationQuery;
    if (data?.createLocation) {
      const newLocation = data.createLocation.location;
      onCreateLocation && onCreateLocation(newLocation);
    }
  }, [createLocationQuery.data]);

  useEffect(() => {
    const { data } = updateLocationsQuery;
    if (data?.updateLocations) {
      onUpdate && onUpdate();
    }
  }, [updateLocationsQuery.data]);

  return (
    <>
      <Formik
        {...getLocationFormFormikConfig(
          existingLocation || null,
          submit,
        )}
      >
        <LocationForm
          isDisabled={isDisabled}
          submitFn={v => submit(v)}
          hideFields={hideFields}
          existingLocation={existingLocation}
          overrideFields={overrideFields}
          hideTypeOptions={hideTypeOptions}
          isLoading={createLocationQuery.loading ||
            updateLocationsQuery.loading}
          submitButtonCopy={submitButtonCopy}
          onBeforeSubmit={onBeforeSubmit}
          defaultAddress={defaultAddress}
        >
          {children}
        </LocationForm>
      </Formik>
    </>
  );
};

export default LocationFormWithFormik;
