const isSSR = typeof window === 'undefined';

export const getSelectedLocationIdFromCookieOrDefault = (
  cookieValue: string | undefined | null,
  defaultLocationId: string | undefined,
  setCookie: (raw: string) => void,
): string | null | undefined => {
  if (isSSR) {
    return null;
  }

  if (cookieValue === null && defaultLocationId) {
    setCookie(defaultLocationId);

    return defaultLocationId;
  }

  return cookieValue;
};

export const getInitialSelectedLocationId = (
  locations: { id: string; }[],
  defaultSelectedLocationId: string | null,
): string | null => {
  if (locations.length > 0) {
    if (defaultSelectedLocationId) {
      const foundSelected = locations.find(
        l => l.id === defaultSelectedLocationId
      );
      if (foundSelected) {
        return defaultSelectedLocationId;
      }
    }

    return locations[0].id;
  }

  if (defaultSelectedLocationId) {
    return defaultSelectedLocationId;
  }

  return null;
};
