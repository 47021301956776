import CenteredSpinner from '../CenteredSpinner';

type ButtonWithSpinnerProps = {
  isLoading: boolean;
  isDisabled?: boolean;
  copy?: string;
  testid?: string;
  classes?: string;
  onClick?: () => void;
}

const ButtonWithSpinner: React.FC<ButtonWithSpinnerProps> = ({
  isLoading,
  isDisabled = false,
  copy = 'Submit',
  testid = '',
  classes,
  onClick,
}) => {
  return (
    <button
      className={classes || `btn-primary relative ${isDisabled ? 'disabled' : ''}`}
      type="submit"
      disabled={isLoading || isDisabled}
      data-testid={testid}
      onClick={onClick}
    >
      <span className={`text-white ${isLoading ? 'opacity-0' : ''}`}>
        {copy}
      </span>
      <CenteredSpinner
        invisible={!isLoading}
        classes="absolute top-1"
      />
    </button>
  );
};

export default ButtonWithSpinner;
