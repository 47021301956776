import { formatServiceTypeSingular, ServiceType } from '@bookpeazy/common-js';
import { Image } from 'theme-ui';
import { useState } from 'react';

type ServiceTypeListItemProps = {
  serviceType: ServiceType;
  onSelect: (t: ServiceType) => void;
  value?: ServiceType[];
};

export const ServiceTypePrettyNames: Partial<Record<ServiceType, string>> = {
  [ServiceType.FITNESS_YOGA]: 'Fitness/Yoga Classes',
  [ServiceType.WELLNESS]: 'Wellness Coach',
  [ServiceType.MASSAGE]: 'Massage Therapy',
  [ServiceType.OIL_CHANGE]: 'Oil Change & Tire Swap',
};

// Deprecated
const phaseOneImages = {
  Esthetician: 'esthetician/nails_lglfvr.webp'
};

// Deprecated
const serviceImages = {
  ...phaseOneImages,
  CarWash: 'car_wash/index.jpg',
  Yoga: 'yoga/index.jpg',
  FitnessClass: 'fitness_class/index.jpg',
  Laundry: 'laundry/index.jpg',
  PersonalShopping: 'personal_shopping/index.jpg',
  Pet: 'dog_walking/index.jpg',
  Wellness: 'wellness/index.jpg',
  General: 'general/index.jpg',
  ChefServices: 'chef_services/index.jpg',
  WineTasting: 'wine_tasting/index.jpg',
  NailTech: 'nail_tech/index.jpg',
  Ergonomics: 'ergonomics/index.jpg',
  Physiotherapy: 'physiotherapy/winter-physiotherapy_kmfoqd.webp',
};

// Deprecated
const v2ServiceImages: Record<string, string> = {
  [ServiceType.HAIRCUT]: 'haircut-min_vvlbvg.webp',
  [ServiceType.MASSAGE]: 'massage-min_ga77iw.webp',
  [ServiceType.DRY_CLEAN]: 'dry_cleaning-min_si9piq.webp',
  [ServiceType.OIL_CHANGE]: 'oil_change-min_f88rhf.webp',
  [ServiceType.DIETITIAN]: 'nutrition-min_ibzps9.webp',
  [ServiceType.PHYSIOTHERAPY]: 'peazy-physio-min_gyptv0.webp',
};

// Deprecated
const getCloudinaryServiceImageURL = (service: string) => {
  return `https://res.cloudinary.com/mimobl/f_auto,dpr_auto,w_auto:50:500/mimobl-app/services/${service}`;
};

// Deprecated
const getCloudinaryServiceImageV2URL = (service: string) => {
  const path = v2ServiceImages[service];
  if (path) {
    return `https://res.cloudinary.com/mimobl/image/upload/v1711745609/mimobl-app/services-v2/${path}`;
  }

  return null;
};

const cfImageIdMap = {
  [ServiceType.REFLEXOLOGY]: 'c3efb0ec-81e9-43ca-92cc-e92848e16800',
  [ServiceType.REIKI]: '3fe8d0da-4f2b-4ef1-31fc-33b6c185a400',
  [ServiceType.WORKSHOP]: 'e8f98f67-fb91-48ff-56d6-ca1c8bfef500',
  [ServiceType.PET_SERVICES]: 'e72880c9-8bb9-4435-efe3-39a802d0b800',
  [ServiceType.PET_GROOMING]: 'e72880c9-8bb9-4435-efe3-39a802d0b800',
  [ServiceType.FITNESS_CLASS]: '8548d1cd-8bd5-40fe-88c2-0f998bbf3a00',
  [ServiceType.FLU_CLINIC]: 'd19ae38e-44b9-41d5-b64b-622d558aba00',
  [ServiceType.DENTAL]: 'e74c6e2b-21b4-4fa2-d2fd-79677ab5d100',
};

const getCloudflareImagePath = (st: ServiceType) => {
  const id = cfImageIdMap[st as keyof typeof cfImageIdMap];
  if (id) {
    return `https://imagedelivery.net/HgicF1m5RsgybzkN2ofRyQ/${id}/public`;
  }

  return null;
};

export const getServiceTypeImage = (service: ServiceType) => {
  const cfImage = getCloudflareImagePath(service);
  if (cfImage !== null) {
    return cfImage;
  }

  const v2Image = getCloudinaryServiceImageV2URL(service);
  if (v2Image !== null) {
    return v2Image;
  }

  let imageName;
  switch (service) {
    case ServiceType.CAR_WASH:
      imageName = serviceImages.CarWash;
      break;
    case ServiceType.LAUNDRY:
      imageName = serviceImages.Laundry;
      break;
    case ServiceType.FITNESS_YOGA:
      imageName = serviceImages.FitnessClass;
      break;
    case ServiceType.DOG_WALKING:
      imageName = serviceImages.Pet;
      break;
    case ServiceType.ESTHETICIAN:
      imageName = serviceImages.Esthetician;
      break;
    case ServiceType.WELLNESS:
      imageName = serviceImages.Wellness;
      break;
    case ServiceType.PERSONAL_SHOPPING:
      imageName = serviceImages.PersonalShopping;
      break;
    case ServiceType.CHEF_SERVICES:
      imageName = serviceImages.ChefServices;
      break;
    case ServiceType.WINE_TASTING:
      imageName = serviceImages.WineTasting;
      break;
    case ServiceType.NAIL_TECH:
      imageName = serviceImages.NailTech;
      break;
    case ServiceType.ERGONOMICS:
      imageName = serviceImages.Ergonomics;
      break;
    default:
      imageName = serviceImages.General;
  }

  return getCloudinaryServiceImageURL(imageName);
};

const ServiceTypeListItem: React.FC<ServiceTypeListItemProps> = ({
  serviceType,
  onSelect
}) => {
  const src = getServiceTypeImage(serviceType);
  const [isClicked, setIsClicked] = useState(false);

  const content = (
    <div
      className="flex flex-col rounded-md overflow-hidden cursor-pointer relative"
    >
      <div
        className="flex justify-center items-center"
      >
        <Image
          src={src}
          alt={
            ServiceTypePrettyNames[serviceType] ||
            formatServiceTypeSingular(serviceType)
          }
          sx={{
            objectFit: 'cover',
            height: ['162px', '300px'],
            minWidth: '100%',
          }}
        />
      </div>
      <span className="font-bold text-white absolute bottom-4 left-4 text-lg"
        style={{
          textShadow: '0px 0px 4px black',
        }}
      >
        {ServiceTypePrettyNames[serviceType] ||
          formatServiceTypeSingular(serviceType)}
      </span>
    </div>
  );

  const item = (
    <div
      className="relative cursor-pointer"
      data-testid={`serviceTypeSelect_${serviceType}`}
      data-cy={`serviceTypeSelect_${serviceType}`}
      onClick={() => {
        onSelect(serviceType);
      }}
    >
      <div
        onClick={() => setIsClicked(true)}
        className={`absolute inset-0 bg-gradient-to-t from-black via-black to-transparent opacity-0 focus:opacity-10 z-10 pointer-events-none ${isClicked ? 'opacity-10' : ''}`}
      />
      {content}
    </div>
  );

  return item;
};

export default ServiceTypeListItem;

