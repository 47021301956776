import React from 'react';
import { ServiceType } from '@bookpeazy/common-js';
import {
  Grid,
} from 'theme-ui';
import ServiceTypeListItem from './ListItem';
import { SUPPORT_EMAIL } from 'Copy';
import SendRequest from 'Images/svg/send-request.svg';

type ServiceTypeSelectProps = {
  onSelect: (types: ServiceType) => void;
  filter?: (t: ServiceType[]) => ServiceType[];
}

const stypes = Object.values(ServiceType);

const ServiceTypeSelect: React.FC<ServiceTypeSelectProps> = ({
  onSelect,
  filter,
}) => {
  const types = filter ? filter(stypes) : stypes;

  return (
    <div className="flex">
      <div
        className="mx-auto w-full overflow-y-hidden"
      >
        <div className="flex flex-col gap-6 justify-center items-center pb-6">
          <div
            className="flex flex-col py-10 bg-[#fef1ee] w-full items-center"
          >
            <div className="sm:w-10/12 md:w-4/5">
              <h1
                className="mb-2 mt-4 font-bold sm:text-2xl md:text-4xl"
              >
                Book a Service
              </h1>
              <span>
                Browse services from providers that come to you
                {' '}and request a booking.
              </span>
            </div>
          </div>
          <Grid sx={{
            width: ['100%', '80%'],
            justifyContent: 'center',
            columnGap: ['.75rem', '27px'],
            rowGap: ['.75rem', '21px'],
            px: ['1rem', '0'],
            gridTemplateColumns: [
              'repeat(2, 1fr)',
              'repeat(3, 1fr)',
            ],
          }}>
            {types.map((s) => (
              <ServiceTypeListItem
                key={s}
                serviceType={s}
                onSelect={onSelect}
              />
            ))}
          </Grid>
          <div
            className="flex justify-center w-full"
          >
            <div
              className="p-4 rounded-lg bg-primary w-full md:w-4/5 lg:w-1/2 relative flex flex-col gap-4 items-start"
            >
              <span className="font-bold text-white text-3xl">
                Looking for something different?
              </span>
              <span className="text-white">
                Send us a request!  We love hearing from our members and we&apos;re always looking for new services to offer.
              </span>
              <a
                href={`mailto:${SUPPORT_EMAIL}`}
                target="_blank"
                referrerPolicy="no-referrer"
                className="py-4 px-6 border-white border-2 border-solid font-bold text-center block text-white rounded-lg">
                Send us a Request
              </a>
              <SendRequest
                className="absolute right-4 bottom-2 sm:hidden lg:block"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceTypeSelect;
