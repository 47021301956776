
type InputWithLabelProps = {
  label?: string;
  help?: string;
  touched?: boolean;
  error?: string | undefined;
  children?: React.ReactNode;
}

const InputWithLabel: React.FC<InputWithLabelProps> = ({
  label,
  help,
  children,
  touched = false,
  error,
}) => {

  return (
    <label>
      <span className="text-xs uppercase font-bold">
        {label}
      </span>
      <div>
        {children}
        <span
          className={`text-xs ${(touched && Boolean(error)) ? 'error' : 'text-black'}`}
        >
          {(touched && error) && error}
          {Boolean(error) || help}
        </span>
      </div>
    </label>
  );
};

export default InputWithLabel;
