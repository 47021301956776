// TODO: pull this out into an address-utils module so we can remove duplicate code from peazy.world
import { ApolloClient } from '@apollo/client';
import {
  LocationType,
  ReferenceType,
  V1Beta3_ReferenceMatchType,
  V2Gateway_QueryLocationsDocument,
  V2Gateway_QueryLocationsQuery,
  V2Gateway_QueryLocationsQueryResult,
  V2Gateway_QueryLocationsQueryVariables,
  V2Gateway_QueryLocationSubscriptionsDocument,
  V2Gateway_QueryLocationSubscriptionsQuery,
  V2Gateway_QueryLocationSubscriptionsQueryResult,
  V2Gateway_QueryLocationSubscriptionsQueryVariables,
} from 'Generated/graphql';
import { GeoBounds } from 'Generated/guestgraphql';

export const DefaultServiceAreaNameForLocationType: Record<
  LocationType, string
> = {
  [LocationType.Business]: 'Lobby',
  [LocationType.Partner]: 'Lobby',
  [LocationType.Individual]: '',
  [LocationType.ServiceProvider]: '',
  [LocationType.IndividualWork]: '',
  [LocationType.IndividualIndeterminate]: '',
  [LocationType.ServiceProviderRetail]: '',
};

export type queryLocations = NonNullable<
  V2Gateway_QueryLocationsQueryResult['data']
>['queryLocations']['locations'];

export type queryLocationSubscriptions = NonNullable<
  V2Gateway_QueryLocationSubscriptionsQueryResult['data']
>['queryLocationSubscriptions'];

const getIndividualLocations = async (
  apolloClient: ApolloClient<object>,
  cookie: string | undefined,
  ownerUid: string,
): Promise<queryLocations | undefined> => {
  const result = await apolloClient.query<
    V2Gateway_QueryLocationsQuery | null,
    V2Gateway_QueryLocationsQueryVariables
  >({
    query: V2Gateway_QueryLocationsDocument,
    fetchPolicy: 'network-only',
    context: cookie ? {
      headers: {
        cookie,
      }
    } : {},
    variables: {
      query: {
        references: [{
          referenceIds: [ownerUid],
          referenceType: ReferenceType.Owner,
        }],
        types: [
          LocationType.Individual,
          LocationType.IndividualWork,
          LocationType.IndividualIndeterminate,
        ],
      },
    },
  });

  if (result?.data?.queryLocations.locations.length) {
    return result.data.queryLocations.locations;
  }
};

const getLocation = async (
  apolloClient: ApolloClient<object>,
  cookie: string | undefined,
  locationUid: string,
): Promise<queryLocations[number] | undefined> => {
  const result = await apolloClient.query<
    V2Gateway_QueryLocationsQuery | null,
    V2Gateway_QueryLocationsQueryVariables
  >({
    query: V2Gateway_QueryLocationsDocument,
    context: cookie ? {
      headers: {
        cookie,
      }
    } : {},
    variables: {
      query: {
        references: [{
          referenceIds: [locationUid],
          referenceType: ReferenceType.Internal,
        }],
      }
    },
  });

  if (result?.data?.queryLocations.locations.length) {
    const loc = result.data.queryLocations.locations.filter(
      s => s.type !== LocationType.Individual,
    )[0];
    return loc;
  }
};

export const getLocationSubscriptions = async (
  apolloClient: ApolloClient<object>,
  cookie: string | undefined,
  ownerUid: string,
): Promise<queryLocationSubscriptions | undefined> => {
  const result = await apolloClient.query<
    V2Gateway_QueryLocationSubscriptionsQuery | null,
    V2Gateway_QueryLocationSubscriptionsQueryVariables
  >({
    query: V2Gateway_QueryLocationSubscriptionsDocument,
    context: cookie ? {
      headers: {
        cookie,
      }
    } : {},
    fetchPolicy: 'network-only',
    variables: {
      query: {
        page: 0,
        pageSize: 50,
        referenceMatches: [{
          match_type: V1Beta3_ReferenceMatchType.Any,
          ids: [ownerUid],
          type: ReferenceType.Owner,
        }],
      }
    },
  });

  return result?.data?.queryLocationSubscriptions;
};

export const getInitialLocationSubscription = async (
  apolloClient: ApolloClient<object>,
  cookie: string | undefined,
  ownerUid: string,
): Promise<queryLocationSubscriptions[number]['location'] | undefined> => {
  const result = await getLocationSubscriptions(apolloClient, cookie, ownerUid);

  if (result) {
    const sub = [...result].filter(
      s => s.location.type !== LocationType.Individual &&
        s.location.type !== LocationType.IndividualWork &&
        s.location.type !== LocationType.IndividualIndeterminate,
    );

    return sub[0] ? sub[0].location : undefined;
  }
};

type dedupeLocations = Pick<queryLocations[number], 'placeId' | 'type'>
export const dedupeIndividualLocationsAgainstSharedLocations =
  <T extends dedupeLocations>(
    individualLocations: T[] | undefined,
    allLocationSubscriptions: T[] | undefined,
  ): T[] | undefined => {
    if (allLocationSubscriptions) {
      const sharedLocationSubscriptions = allLocationSubscriptions.filter(
        s => !([
          LocationType.Individual,
          LocationType.IndividualIndeterminate,
          LocationType.IndividualWork,
        ].includes(s.type)),
      );
      const indivLocs = individualLocations?.filter(
        il => !sharedLocationSubscriptions.some(sl => sl.placeId === il.placeId)
      );

      return indivLocs;
    }

    return individualLocations;
  };

export const GetSelectedLocationForUser = async (
  apolloClient: ApolloClient<object>,
  cookie: string | undefined,
  ownerUid: string,
  selectedLocationUid: string | undefined,
): Promise<Omit<queryLocations[number], 'serviceAreas'> | undefined> => {
  if (selectedLocationUid) {
    const selectedLocation =
      await getLocation(apolloClient, cookie, selectedLocationUid);
    if (selectedLocation) {
      return selectedLocation;
    }
  }

  const sharedLocationsSubs =
    await getLocationSubscriptions(apolloClient, cookie, ownerUid);
  const sharedLocations = sharedLocationsSubs?.map(s => s.location);

  const allIndivLocs =
    await getIndividualLocations(apolloClient, cookie, ownerUid);
  const indivLocs = dedupeIndividualLocationsAgainstSharedLocations(
    allIndivLocs,
    sharedLocations,
  );
  if (indivLocs?.length) {
    return indivLocs[0];
  }

  const locSub =
    await getInitialLocationSubscription(apolloClient, cookie, ownerUid);

  return locSub;
};

function getNthOccurrence(string: string, char: string, n: number) {
  let count = 0;
  for (let i = 0; i < string.length; i++) {
    if (string.charAt(i) === char) {
      count++;
      if (count === n) {
        return i;
      }
    }
  }
  return -1;
}

export const trimFormattedAddress = (locationFormattedAddress: string) => {
  const nthIndex = getNthOccurrence(locationFormattedAddress, ',', 2);
  const trimmed = nthIndex > 0 ?
    locationFormattedAddress.substring(0, nthIndex) :
    locationFormattedAddress;

  return trimmed;
};

export const formattedAddressToStreetAddress = (locationFormattedAddress: string) => {
  const nthIndex = getNthOccurrence(locationFormattedAddress, ',', 1);
  const trimmed = nthIndex > 0 ?
    locationFormattedAddress.substring(0, nthIndex) :
    locationFormattedAddress;

  return trimmed;
};

export const getLocationTitle = (
  locationType: LocationType,
  locationName: string,
  locationFormattedAddress: string,
) => {
  if (locationType === LocationType.Individual) {
    return 'Home';
  }

  if (locationType === LocationType.IndividualWork) {
    return 'Work';
  }

  if (locationType === LocationType.IndividualIndeterminate) {
    const trimmed = trimFormattedAddress(locationFormattedAddress);
    return trimmed;
  }

  return locationName;
};

export const isLocationWithinBounds = (
  lat: number,
  lng: number,
  bounds: {
    min: Pick<GeoBounds['min'], 'lat' | 'lng'>;
    max: Pick<GeoBounds['max'], 'lat' | 'lng'>;
  },
): boolean => {
  const {
    lat: minLat,
    lng: minLng,
  } = bounds.min;
  const {
    lat: maxLat,
    lng: maxLng,
  } = bounds.max;

  return (lat >= minLat && lat <= maxLat) && (lng >= minLng && lng <= maxLng);
};

export const LabelForLocationType: Record<
  LocationType, string
> = {
  [LocationType.Business]: 'Office',
  [LocationType.Partner]: 'Location',
  [LocationType.Individual]: 'Home',
  [LocationType.ServiceProvider]: 'Location',
  [LocationType.IndividualWork]: 'Work',
  [LocationType.IndividualIndeterminate]: 'Home',
  [LocationType.ServiceProviderRetail]: 'Retail',
};

export const formatAddressWithExtension = (
  formattedAddress: string,
  extendedAddress: string | null | undefined,
) => {
  if (extendedAddress) {
    const f = `${extendedAddress}-${formattedAddress}`;

    return f;
  }

  return formattedAddress;
};

export const getDefaultSelectedLocationId = (
  customerSelectedLocationId: string | null,
  locations: { id: string; }[],
) => {
  if (customerSelectedLocationId === null && locations.length > 0) {
    return locations[0].id;
  }

  return customerSelectedLocationId;
};

export const sortLocationsByStreetAddress = <T extends { streetAddress: string; }[]>(
  locs: T
): T => {
  return [...locs].sort(
    (a, b) => a.streetAddress > b.streetAddress ? 1 : -1
  ) as T;
};

export const dedupeLocationSubscriptions = <T extends { location: { placeId: string; } }[]>(items: T): T => {
  const seen = new Map();
  return items.filter(item => {
    return !seen.has(item.location.placeId) && seen.set(item.location.placeId, true);
  }) as T;
};

export const dedupeLocations = <T extends { placeId: string; }[]>(items: T): T => {
  const seen = new Map();
  return items.filter(item => {
    return !seen.has(item.placeId) && seen.set(item.placeId, true);
  }) as T;
};

