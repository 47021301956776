import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="bg-gray-800 py-6 px-9 flex sm:flex-col-reverse md:flex-row justify-between sm:items-start md:items-end sm:gap-2 md:gap-0">
      <div>
        <div className="flex gap-4 mb-2">
          <a
            className="font-bold text-white"
            href="mailto:support@bookpeazy.com"
            target="_blank"
            rel="noreferrer"
          >
            Contact Us
          </a>
          <a
            className="font-bold text-white"
            href="https://bookpeazy.com/blog"
            target="_blank"
            rel="noreferrer"
          >
            Blog
          </a>
          <a
            className="font-bold text-white"
            href="https://bookpeazy.com/faqs"
            target="_blank"
            rel="noreferrer"
          >
            FAQ
          </a>
        </div>
        <span className="text-left text-white">
          &copy; 2024 Peazy, all rights reserved
        </span>
      </div>
      <div className="flex gap-4">
        <a
          className="font-bold text-white"
          href="https://www.bookpeazy.com/peazy-privacypolicy"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
        <a
          href="https://www.bookpeazy.com/peazy-terms-of-service"
          target="_blank"
          rel="noreferrer"
          className="font-bold text-white"
        >
          Terms of Service
        </a>
      </div>
    </footer>
  );
};

export default Footer;

